<template>

<div class="file-card-container">
  <div class="file-card"
    @click="hoverFile"
  > 
    <div class="fc-header">
      <div class="fc-file-type"> 
        <feather-icon
          :icon="fileType == 'video' ? 'VideoIcon' : 'ImageIcon'"
          size="16"
        />
        
      </div>
      <h6 class="fc-file-name"> {{ file.original_filename }}</h6>
      <div class="fc-toolbar">
        <feather-icon
          icon="Trash2Icon"
          size="16"
          class="text-danger cursor-pointer"
          @click="deleteFlow"
        />
      </div>
    </div> 
    
    <div class="fc-thumbnail"> 
      <img
        v-if="fileType == 'image'"
        style="object-fit: cover;"
        :src="
          (file.metadata && file.metadata.image_variants && file.metadata.image_variants.low.url) 
          || file.url
        "
        width="100%"
        height="100%"
      />
      <img
        v-else
        style="object-fit: cover;"
        :src="videoPlaceholder"
        width="100%"
        height="100%"
      />
    </div>
  </div>  
</div>

</template>


<script>

export default {
  data() {
    return {
      
    }
  },

  props: ['file'],
  methods: {
    hoverFile() {
      this.$emit('hover-file', {
        ...this.file,
        type: this.fileType,
        ext: this.fileExtension
      });
    },
    deleteFlow() {
      this.$emit('delete-file', this.file);
    }
  },

  computed: {
    fileType() {
      if(this.file.mime_type) {
        return this.file.mime_type.split('/')[0];
      }
    },
    fileExtension() {
      if(this.file.mime_type) {
        return this.file.mime_type.split('/')[1];
      }
    },
    videoPlaceholder() {
      return require('@/assets/images/video-placeholder.png');
    }
  }
}
</script>