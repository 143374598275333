<<template>
  <div class="col-3 card p-1 mx-1" >
    <div v-if="hoveredFileObj.id">
      <h6>
        <feather-icon
          :icon="fileType == 'image' ? 'ImageIcon' : 'VideoIcon'"
          size="18"
        />
        <span class="mx-1"> {{ hoveredFileObj.original_filename }} </span>
      </h6>

      <div>
        <div class="p-1">
          <img 
            v-if="fileType == 'image'"
            :src="hoveredFileObj.url"
            width="120"
            height="120"
            style="object-fit: cover; border-radius: 12px; width: 100%;"
          />
          <img 
            v-else
            :src="videoPlaceholder"
            width="120"
            height="120"
            style="object-fit: cover; border-radius: 12px; width: 100%;"
          />

        </div>

        <hr />

        <h5 class="my-1"> {{ $t('File details') }}</h5>
        
        <div 
          v-for="(key, keyInd) in detailsKeys"
          :key="keyInd"  
        >
          <h6> {{ $t(key.title) }} </h6>
          <p v-if="key.key == 'created_at'">  {{ hoveredFileObj[key.key].split('T')[0] || ' - '}}</p>
          <p v-else-if="key.key == 'metadata' && hoveredFileObj[key.key].width">  {{ hoveredFileObj[key.key].width  + ' x ' + hoveredFileObj[key.key].height || ' - '}}</p>
          <p v-else-if="key.key == 'url'"> <a target="_blank" :href="hoveredFileObj.url" class="link-ellipsis"> {{ `${hoveredFileObj.url}` }}</a> </p> 
          <p 
            v-else-if="key.key == 'image_variations'"
          > 
            <strong>{{"High:"}}</strong> <a target="_blank" :href="hoveredFileObj.metadata.image_variants.high.url" class="link-ellipsis"> {{ `${hoveredFileObj.metadata.image_variants.high.url}` }}</a>
            <strong>{{"Medium:"}}</strong> <a target="_blank" :href="hoveredFileObj.metadata.image_variants.high.url" class="link-ellipsis"> {{ `${hoveredFileObj.metadata.image_variants.medium.url}` }}</a>
            <strong>{{"Low:"}}</strong> <a target="_blank" :href="hoveredFileObj.metadata.image_variants.high.url" class="link-ellipsis"> {{ `${hoveredFileObj.metadata.image_variants.low.url}` }}</a>
            <strong>{{"Thumbnail:"}}</strong> <a target="_blank" :href="hoveredFileObj.metadata.image_variants.high.url" class="link-ellipsis"> {{ `${hoveredFileObj.metadata.image_variants.thumbnail.url}` }}</a>
          </p>
          <p v-else>  {{ hoveredFileObj[key.key]  || ' - '}}</p>
        </div>
      </div>

      <!-- <HoverableObjectForm
        v-if="!formLoading"
        :editObj="formObj"
        :formFields="formFields"
      ></HoverableObjectForm> -->
    </div>
    <h6 
      v-else
      class="pt-1"
    >{{$t('Select an item to see the details')}}</h6>

  </div>
    
  
</template>

<script>
import {
  BButton
} from 'bootstrap-vue';
import HoverableObjectForm from './HoverableObjectForm.vue';
import { mapActions } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    HoverableObjectForm,
    ToastificationContent
  },
  data() {
    return {
      
      formObj: {},
      formLoading: false
    }
  },
  props: ['hoveredFileObj'],
  watch: {
    hoveredFileObj: function() {
      this.formLoading = true;
      this.formObj = {
        titles: this.hoveredFileObj.title,
        descriptions: this.hoveredFileObj.description,
        keywords: this.hoveredFileObj.keywords
      };
      setTimeout(() => {
        this.formLoading = false;
      }, 20);
    }
  },
  methods: {
    ...mapActions(['updateFile']),
    updateDataField(field, data) {
      let payload = this.setPayloadData(field, data)
      this.updateFile({
        id: this.hoveredFileObj.id,
        ...payload
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Data Updated !'),
            variant: 'success',
          },
        });
        // this.$emit('dataFieldUpdated');
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Something happened !'),
            variant: 'danger',
          },
        });
      })
    },
    setPayloadData(field, data) {
      let payload = {};
      if(field.model == 'titles') {
        payload = {
          titles: {
            en: data,
            ar: data,
          }
        }
      } else if (field.model == 'descriptions') {
        payload = {
          descriptions: {
            en: data,
            ar: data,
          }
        }
      } else {
        payload[field.model] = data;
      }
      return payload;
    }
  },
  computed: {
    formFields() {
      return [
        {
          name: "Title",
          type: "text",
          rules: "",
          model: "titles",
          class: 'cols-12',
          onChange: this.updateDataField,
        },
        {
          name: "Description",
          type: "text",
          rules: "",
          model: "descriptions",
          class: 'cols-12',
          onChange: this.updateDataField,
        },
        {
          name: "Keywords",
          type: "text",
          rules: "",
          model: "keywords",
          class: 'cols-12',
          onChange: this.updateDataField,
        },
      ]
    },
    detailsKeys() {
      if(this.fileType == 'video') {
        return [
          { title: 'Created At', key: 'created_at' },
          { title: 'Type', key: 'type' },
          { title: 'Original Link', key: 'url',},
        ]
      } else {
        return [
          // { title: 'ID', key: 'id' },
          { title: 'Created At', key: 'created_at' },
          { title: 'Type', key: 'type' },
          { title: 'Dimensions', key: 'metadata',},
          { title: 'Original Link', key: 'url',},
          { title: 'Variations', key: 'image_variations',},
        ]
      }
    },
    fileType() {
      if(this.hoveredFileObj.directory == 'videos') {
        return 'video';
      } else return 'image';
    },
    videoPlaceholder() {
      return require('@/assets/images/video-placeholder.png');
    }
  }
}
</script>