<template>

  <form class="row px-1">

    <div 
      v-for="(field, fieldInd) in formFields"
      :key="fieldInd"
      :class="field.class"
    >
      <label :for=field.name> {{ field.name }} </label>
      <b-form-input
        :id="field.name"
        type="text"
        v-model="formData[field.model]"
        @change="updateFileData(field)"
      />

    </div>
  </form>
</template>

<script>
import {
  BFormInput
} from 'bootstrap-vue';

export default {
  components: {
    BFormInput
  },
  data() {
    return {
      formData: {}
    }
  },

  mounted() {
    if(this.editObj) {
      this.formData = { ...this.editObj };
    }
  },
  props: [
    'formFields',
    'editObj'
  ],
  methods: {
    updateFileData(field) {
      if(field.onChange) {
        field.onChange(field, this.formData[field.model]);
      }
    } 
  }
}
</script>