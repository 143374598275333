<template>
<div>
  <div class="card p-1 my-2 col-12" >
    <div 
      class="dz-selectable" 
      :class="{ 'limited': limitedHeight }"
      v-if="allFiles.length > 0"
    >
      <div 
        class="dz-img-selectable-add"
        @click="openUpload"  
      >
        {{ ' + ' }}
      </div>
      
      <div 
        class="dz-img-selectable-container" 
        :class="{
          'selected': selectedFileIndex == ind
        }"
        v-for="(file, ind) in allFiles"
        :key="file.id"
        v-if="file != null"
      >
        <img 
          :src="file.url"
          width="80"
          height="80"
          class="mx-2"
          @click="selectImage(file, ind)"
        />
        <!-- <div class="dz-selectable-toolbar">
          <input
            type="checkbox"
            v-model="selectedFile[ind]"
            @change="selectFile(file, ind)"
          />
        </div> -->
      </div>

    </div>
    <h4
      v-else
      class="text-center my-4"
    >
      {{ $t('No Files Yet') }}
    </h4>
  </div>

  <div class="w-100 text-right" v-if="allFiles.length > 0">
    <hr />
    <b-button
      variant="primary"
      @click="selectFile"
    >
      {{ 'Submit' }}
    </b-button>

  </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import { BButton } from 'bootstrap-vue';
export default {
  components: {
    BButton
  },
  data() {
    return {
      selectedFile: [],
      selectedFileObj: {},
      selectedFileIndex: -1
    }
  },
  watch: {
    allFiles: function() {
      if(this.allFiles.length > 0 && this.selectable) {
        let index = this.allFiles.findIndex(file => file.id == this.selectable.id);
        if(index != -1) {
          this.selectedFileObj = this.allFiles[index];
          this.selectedFile[index] = true;
          this.selectedFileIndex = index;
        }
      }
      // this.showSelectedFile()
    },
  },
  props: ['selectable', 'limitedHeight', 'openUpload'],
  methods: {
    selectFile(file, ind) {
      this.$emit('selectFile', this.selectedFileObj, this.selectedFileIndex);
    },
    selectImage(file, ind) {
      this.selectedFileObj = file;
      this.selectedFileIndex = ind;
    }
  },
  computed: {
    ...mapGetters(['allFiles'])
  }
}
</script>