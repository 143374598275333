<template>
  <div
    class="dz-uploading-progress"
    :class="{ 'closed' : isUploadingProgressClosed }"
  >
    <div class="dz-uploading-progress-header" @click="openProgress"> 
      <h6> {{ $t('Uploading ...') }} </h6>
      <feather-icon
        v-if="!isUploadingProgressClosed"
        icon="XIcon"
        size="18"
        class="pointer"
        @click="closeProgress"
      ></feather-icon>
    </div>
    <div class="dz-uploading-progress-items">
      
      <div
        v-for="file in uploadingFiles"
        :key="file.id"
        class="dz-uploading-progress-item text-center"
      >
        <img 
          :src="file.src"
          width="40"
          height="40"
          class="mx-1"
        />
        <h6 class="w-75">
          {{ file.file.name }}
          <div 
            class="progress mt-50" 
            role="progressbar" 
            aria-valuenow="0" 
            aria-valuemin="0" 
            aria-valuemax="100"
          >
            <div class="progress-bar" :style="`width: ${file.progress}%`"></div>
          </div>
          <small> {{file.progress}} % </small>
        </h6>
      </div>
    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['isUploadingProgressClosed'],
  methods: {
    closeProgress() {
      if(!this.isUploadingProgressClosed) {
        this.$emit('closeProgress');
      }
    },
    openProgress() {
      if(this.isUploadingProgressClosed) {
        this.$emit('openProgress');
      }
    },
  },
  computed: {
    ...mapGetters(['uploadingFiles']),
  }
}
</script>