<template>
  <div class="card p-1 my-2 " >
    <div
      class="d-flex"
    >
      <img 
        v-if="!selectedFileObj.id"
        src="./noimage.png"
        width="120"
        height="120"
        class="mx-2"
      />
      <div 
        v-else
        class="mr-1 gallery-img-holder"
      >
        <img 
          v-if="!types || types.includes('image')"
          :src="selectedFileObj.url"
          width="120"
          height="120"
          class="mx-2"
        />
        <img 
          v-else
          src="./camera-roll.webp"
          width="120"
          height="120"
          class="mx-2"
        />
        <div
          class="gallery-img-toolbar"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-danger cursor-pointer"
            @click="deleteFlow()"
          />
        </div>
      </div>
      <div class="d-flex flex-column">
        <b-button 
          variant="success" 
          class="my-1"
          @click="openUpload"
        > 
          Upload 
        </b-button>
        <b-button 
          variant="success"
          @click="openSelectPopup"
        > 
          Select 
        </b-button>
      </div>
    </div>
    
  </div>
</template>

<script>
import { 
  BButton
} from 'bootstrap-vue';

export default {
  components: {
    BButton
  },
  props: ['selectedFileObj', 'types', 'selectable'],
  methods: {
    deleteFlow() {
      this.$emit('deleteFlow', this.selectedFileObj)
    },
    openUpload() {
      this.$emit('openUpload', this.selectedFileObj)
    },
    openSelectPopup() {
      this.$emit('openSelectPopup', this.selectedFileObj)
    },
  }
}
</script>