<template>
  <!-- <b-card> -->
  <!-- </b-card> -->
  <div >
    <!-- <UploadImages @changed="handleImages"/>   -->
    <DropZone 
      :multiple="true"
      :dropzone="true"
      :hoverable="true"  
      :types="['video', 'image']"
    />

    <hr />
    <!-- <b-card v-if='allMedia && allMedia.length > 0' >
      <div class="d-flex flex-wrap">
        <div 
          v-for='file in allMedia' 
          :key='file.id' 
          class="mr-1 gallery-img-holder"
          v-if="file.file != null"
        >
          <b-img 
            class="m-1 image-border"
            rounded
            height="80" 
            width="80" 
            :src="file.file.url" 
          />
          <div
            class="gallery-img-toolbar"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-danger cursor-pointer "
              @click="deleteItem(file)"
            />
          </div>
        </div>
        
      </div>
    </b-card> -->
  </div>
</template>

<script>
import UploadImages from "vue-upload-drop-images";
import DropZone from '@/@core/components/forms/dropzone/DropZone.vue';
import {
  BCard, BImg, BButton
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  components: 
  {
    UploadImages,
    BCard, BImg, BButton,
    DropZone
  },
  data() {
    return {
      files: []
    }
  },
  props: ['confirmDeleteFullItem'],
  mounted() {
    // this.getMedia({
    //   limit: 100,
    // })
    //   .then(res => {
    //     console.log(res.data.data)
    //   })
  },
  methods: {
    ...mapActions(['getAllFiles', 'saveFile', 'deleteFile', 'getMedia', 'createMedia', 'deleteMedia']),
    handleImages(files) {
      this.files = files;
      this.$forceUpdate();
    },
    submit() {
      this.saveFile(this.files[0])
        .then(res => {
          // this.$router.go()
          console.log(res);
        })
    },
    async deleteFlow(file) {
      await this.deleteFile(file.file_id) 
        .then(res => {
          this.deleteMedia(file.id)
            .then(mediaRes => {
              this.getMedia({});
            })
        })
    },
    deleteItem(file) {
      this.confirmDeleteFullItem(this.deleteFlow, file );
    },
  },

  computed: {
    ...mapGetters(['allFiles', 'uploadingFiles', 'allMedia'])
  }
}

</script>